import React, { Fragment, useEffect, useRef, useState } from 'react';

import SvgIcon from "../../Icons";

import './index.sass';


const BasicDropdown = ({ bodyTitle,
                         currentValue,
                         handleChange,
                         handleSetValue,
                         options,
                         placeholder,
                         handleActiveState,
                         triggerIcon,
                         wrapperClass }) => {

  const triggerWrapper = useRef();
  const bodyWrapper = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [stateValue, setStateValue] = useState(null);


  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
  }, []);


  const handleClickOutside = (e) => {
    const dropdownBody = bodyWrapper && bodyWrapper.current;

    if (dropdownBody && e.target !== triggerWrapper.current) {
      setIsOpen(false);
      handleActiveState && handleActiveState(false);
    }
  };


  const handleOpen = () => {
    const currentState = isOpen;

    setIsOpen(!currentState);
    handleActiveState && handleActiveState(!currentState);
  };


  const setOption = (value) => {
    handleSetValue ? handleSetValue(value) : setStateValue(value);
    handleChange(value);
  };

  const value = currentValue || stateValue;


  return (
    <div className={`basic-dropdown ${isOpen ? 'open' : ''} ${wrapperClass || ''}`}>
      <div className="basic-dropdown__trigger" onClick={handleOpen} ref={triggerWrapper}>
        { value
          ?
            <div className="basic-dropdown__value">
              { triggerIcon && <SvgIcon name={triggerIcon} size={18} className="basic-dropdown__trigger_icon" /> }
              <div className="basic-dropdown__label">{ value.label || value.id }</div>
            </div>
          :
            <Fragment>
              { triggerIcon && <SvgIcon name={triggerIcon} size={18} className="basic-dropdown__trigger_icon" /> }
              { placeholder }
            </Fragment>
        }
        { isOpen
          ? <SvgIcon name="expand_less" size={24} className="basic-dropdown__trigger_arrow" />
          : <SvgIcon name="expand_more" size={24} className="basic-dropdown__trigger_arrow" />
        }
      </div>

      { isOpen && options && options.length &&
        <div className="basic-dropdown__body" ref={bodyWrapper}>
          { bodyTitle && <div className="basic-dropdown__body-title">{ bodyTitle }</div> }
          { options.map(option =>
            <div className="basic-dropdown__option" key={`dropdown-option-${option.id}`} onClick={() => setOption(option)}>
              { option.icon && <SvgIcon name={option.icon} size={20} /> }
              { option.label || option.id }
            </div>
          )}
        </div>
      }
    </div>
  )
}

export default BasicDropdown;
