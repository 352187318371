import React, { useEffect, useRef, useState } from 'react';

import SvgIcon from "../../Icons";

import './index.sass';


const NumberDropdown = ({ bodyLabel,
                          error,
                          id,
                          handleChange,
                          handleActiveState,
                          label,
                          placeholder,
                          triggerIcon,
                          value,
                          wrapperClass }) => {

  const triggerWrapper = useRef();
  const bodyWrapper = useRef();

  const [isOpen, setIsOpen] = useState(false);


  useEffect(() => {
    window.addEventListener('click', handleClickOutsideTest);
  }, []);


  const handleClickOutsideTest = (e) => {
    const target = e.target;
    const trigger = triggerWrapper.current;
    const dropdownBody = bodyWrapper && bodyWrapper.current;

    if (dropdownBody && target !== trigger && !trigger.contains(target) && target !== dropdownBody && !dropdownBody.contains(target)) {
      setIsOpen(false);
      handleActiveState && handleActiveState(false);
    }
  };


  const handleOpen = () => {
    const currentState = isOpen;

    setIsOpen(!currentState);
    handleActiveState && handleActiveState(!currentState);
  };

  const valuePresent = value && value !== '0';


  return (
    <div className={`number-dropdown ${wrapperClass || ''} ${isOpen ? 'open' : ''}`}>
      <div className="number-dropdown__trigger" onClick={handleOpen} ref={triggerWrapper}>
        { triggerIcon &&
          <SvgIcon name={triggerIcon} size={18} className="number-dropdown__trigger_icon" />
        }

        <div className={`number-dropdown__value ${valuePresent ? 'active' : ''}`}>
          { valuePresent ? value : placeholder }
        </div>

        { label &&
          <div className={`number-dropdown__label ${valuePresent ? 'active' : ''}`}>
            { label }
          </div>
        }
      </div>

      { isOpen &&
        <div className="number-dropdown__body" ref={bodyWrapper}>

          { bodyLabel &&
            <div className="number-dropdown__body-title">
              { bodyLabel }
            </div>
          }

          <div className="number-dropdown__body-content">
            <div className="number-dropdown__body-input-wrapper">
              <div className={`number-dropdown__body-input-control decrement ${!value ? 'inactive' : 0}`}
                   onClick={() => valuePresent ? handleChange(Number(value) - 1) : null}>
                <SvgIcon name="minus" size={20} />
              </div>

              <input
                className={`form-group__input ${valuePresent ? 'active' : ''}`}
                data-tip={error}
                id={id}
                name={id}
                onChange={(e) => (/^\d+$/.test(e.target.value) || !e.target.value) && handleChange(e.target.value)}
                placeholder="0"
                type="text"
                value={valuePresent ? value : ''}
              />

              <div className="number-dropdown__body-input-control increment" onClick={() => handleChange(Number(value) + 1)}>
                <SvgIcon name="plus" size={20} />
              </div>
            </div>

            <div className="number-dropdown__body-buttons">
              <div className={`number-dropdown__body-clear-btn ${ !valuePresent ? 'inactive' : ''}`} onClick={value ? () => handleChange(0) : null}>
                Ryd
              </div>
              <div className="number-dropdown__body-save-btn"
                   onClick={() => {
                     setIsOpen(false);
                     handleActiveState && handleActiveState(false);
                   }}>
                Gem
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}


export default NumberDropdown;
